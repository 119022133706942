<template>
  <div class="join-container">
    <!-- 广告区 -->
    <div
      class="join-swiper"
      :style="'height:' + swiperHeight + 'px'"
    >
      <p>加盟我们</p>
      <span>10年的企业人力资源服务经验，成功合作90000+招聘方用户，</span>
      <span>15项行业荣誉证书、6项企业品牌认证、23项软件著作权</span>
    </div>
    <!-- 劳务是个好行业 -->
    <div class="join-industry">
      <div class="join-industry-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">劳务是个好行业</span>
          <span class="title-content-cn">Labor service is a good industry</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <div class="join-industry-content">
        <div class="industry-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan36" />
          </svg>
          <p>企业刚需市场好</p>
        </div>
        <div class="industry-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan37" />
          </svg>
          <p>支持就业政策好</p>
        </div>
        <div class="industry-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan35" />
          </svg>
          <p>切入行业投资少</p>
        </div>
        <div class="industry-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan34" />
          </svg>
          <p>人多钱多生态好</p>
        </div>
      </div>
    </div>
    <!-- 劳务邦是个好平台 -->
    <div class="join-terrace">
      <div class="join-terrace-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">劳务邦是个好平台</span>
          <span class="title-content-cn">Laobour Group is a good platform</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <div class="join-terrace-content">
        <p class="terrace-content-title">
          “ 七剑下天山 ，一起打天下！”
        </p>
        <div class="terrace-content">
          <div class="terrace-content-left">
            <!-- 牌子 -->
            <div class="content-left-item paizi">
              <div class="terrace-content-item">
                <p>1 牌子</p>
                <span>品牌资源</span>
                <span>品牌中心/营销策划机构</span>
              </div>
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan49" />
              </svg>
            </div>
            <!-- 银子 -->
            <div class="content-left-item yingzi">
              <div class="terrace-content-item">
                <p>6 银子</p>
                <span>货币资源</span>
                <span>财务中心/投融机构/</span>
                <span>财税咨询机构</span>
              </div>
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan54" />
              </svg>
            </div>
            <!-- 路子 -->
            <div class="content-left-item luzi">
              <div class="terrace-content-item">
                <p>5 路子</p>
                <span>客户资源</span>
                <span>商务中心/渠道中心/公关中心</span>
              </div>
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan53" />
              </svg>
            </div>
          </div>
          <div class="terrace-content-right">
            <!-- 脑子 -->
            <div class="content-right-item naozi">
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan50" />
              </svg>
              <div class="terrace-content-item">
                <p>2 脑子</p>
                <span>智力资源</span>
                <span>培训中心/培训咨询机构</span>
              </div>
            </div>
            <!-- 班子 -->
            <div class="content-right-item banzi">
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan51" />
              </svg>
              <div class="terrace-content-item">
                <p>3 班子</p>
                <span>人力资源</span>
                <span>人力资源中心/</span>
                <span>人力资源咨询机构</span>
              </div>
            </div>
            <!-- 斧子 -->
            <div class="content-right-item fuzi">
              <svg
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href="#icon-ziyuan52" />
              </svg>
              <div class="terrace-content-item">
                <p>4 斧子</p>
                <span>工具/软件</span>
                <span>IT公司、网络公司</span>
              </div>
            </div>
          </div>
          <p class="seven">
            7盘子
          </p>
        </div>
      </div>
    </div>
    <!-- 你是谁 -->
    <div class="join-user">
      <p class="join-user-title">
        如果您是当地
      </p>
      <div class="join-user-content">
        <div class="user-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan48" />
          </svg>
          <p>
            <span>人力资源负责人</span>
            <span>或相关负责人</span>
          </p>
        </div>
        <div class="user-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan43" />
          </svg>
          <p>
            <span>人力中介负责人</span>
            <span>或相关负责人</span>
          </p>
        </div>
        <div class="user-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan47" />
          </svg>
          <p>
            <span>人力市场负责人</span>
            <span>或相关负责人</span>
          </p>
        </div>
        <div class="user-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan44" />
          </svg>
          <p>
            <span>职业培训机构负责人</span>
            <span>或相关负责人</span>
          </p>
        </div>
        <div class="user-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan46" />
          </svg>
          <p>
            <span>职业学校就业负责人</span>
            <span>或相关负责人</span>
          </p>
        </div>
        <div class="user-content-item">
          <svg
            class="icon"
            aria-hidden="true"
          >
            <use xlink:href="#icon-ziyuan45" />
          </svg>
          <p>
            <span>政府就业系统负责人</span>
            <span>或相关负责人</span>
          </p>
        </div>
      </div>
    </div>
    <!-- 加盟流程 -->
    <div class="join-step">
      <div class="join-step-title common-title">
        <span class="commm-title-label" />
        <div class="common-title-content">
          <span class="title-content-zh">加盟步骤</span>
          <span class="title-content-cn">Joining steps</span>
        </div>
        <span class="commm-title-label" />
      </div>
      <div class="join-step-detail">
        <svg
          class="icon icon-step"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan38" />
        </svg>
        <svg
          class="icon icon-jiantou"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan33" />
        </svg>
        <svg
          class="icon icon-step"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan39" />
        </svg>
        <svg
          class="icon icon-jiantou"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan33" />
        </svg>
        <svg
          class="icon icon-step"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan40" />
        </svg>
        <svg
          class="icon icon-jiantou"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan33" />
        </svg>
        <svg
          class="icon icon-step"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan41" />
        </svg>
        <svg
          class="icon icon-jiantou"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan33" />
        </svg>
        <svg
          class="icon icon-step"
          aria-hidden="true"
        >
          <use xlink:href="#icon-ziyuan42" />
        </svg>
      </div>
      <!-- 加盟热线 -->
      <div class="join-tool-tel">
        <span class="btns">了解咨询</span>
        <span class="telephone">热线：<span>400-030-5180</span></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      swiperHeight: 0,
      clientWidth: 0
    }
  },
  mounted () {
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    this.swiperHeight = Math.round(this.clientWidth / 4)
    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.swiperHeight = Math.round(this.clientWidth / 4)
    })
  },
  methods: {}
}
</script>
<style lang="less" socoped>
.join-container {
  // background: #fafafa;
  background: #fff;
  /* 公共的按钮 */
  .join-common-btn {
    text-align: center;
    padding: 30px 0;
    margin: 0;
    span {
      display: inline-block;
      width: 180px;
      height: 60px;
      background: #157fd2;
      border-radius: 10px;
      color: #fff;
      line-height: 60px;
      font-size: 23px;
      cursor: pointer;
    }
  }
  /* 公共的热线电话 */
  .join-tool-tel {
    display: flex;
    align-items: center;
    justify-content: center;
    .btns {
      display: block;
      background: #157fd2;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }
    .telephone {
      color: #157fd2;
      display: block;
      border: 1px solid #3f75a2;
      border-radius: 8px;
      span {
        font-weight: bold;
      }
    }
  }
  /* 广告区 */
  .join-swiper {
    width: 100%;
    // height: 500px;
    background-image: url("../assets/Images/join-us-swiper.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* 劳务是个好行业 */
  .join-industry {
    background: #fafafa;
    .join-industry-title {
      .common-title-content {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .join-industry-content {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      .industry-content-item {
        p {
          color: #4277b6;
        }
      }
    }
  }
  /* 劳务邦是个好平台 */
  .join-terrace {
    width: 100%;
    background-image: url("../assets/Images/join-bg-one.jpg");
    background-repeat: no-repeat;
    position: relative;
    .join-terrace-title {
      .common-title-content {
        margin-left: 0;
        margin-right: 0;
        span {
          color: #fff;
        }
      }
    }
    .join-terrace-content {
      .terrace-content-title {
        font-family: "joinFamily";
        color: #fff;
        letter-spacing: 2px;
      }
      .terrace-content {
        display: flex;
        align-items: center;
        position: relative;
        .terrace-content-left,
        .terrace-content-right {
          width: 50%;
          .content-left-item,
          .content-right-item {
            display: flex;
            align-items: center;
            // justify-content: center;
            .terrace-content-item {
              display: flex;
              flex-direction: column;
              color: #fff;
              align-items: flex-start;
              justify-content: center;
            }
          }
        }
      }
    }
  }
  /* 你是谁 */
  .join-user {
    width: 100%;
    // height: 1147px;
    background-image: url("../assets/Images/join-bg-two.jpg");
    background-repeat: no-repeat;
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    .join-user-title {
      color: #fff;
      letter-spacing: 2px;
    }
    .join-user-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .user-content-item {
        width: 33%;
        p {
          color: #fff;
          span {
            display: block;
          }
        }
      }
    }
  }
  /* 加盟流程 */
  .join-step {
    background: #fff;
    .join-step-title {
      .common-title-content {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .join-step-detail {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {
  /* 广告区 */
  .join-swiper {
    p {
      font-size: 40px;
      letter-spacing: 3px;
      margin-top: -10px;
      margin-bottom: 25px;
    }
    span {
      font-size: 17px;
      line-height: 35px;
      letter-spacing: 1px;
    }
  }
  /* 劳务邦是个好平台 */
  .join-industry {
    .join-industry-title {
      .commm-title-label {
        width: 110px;
        &:first-child {
          margin-right: -10px;
        }
        &:last-child {
          margin-left: -10px;
        }
      }
    }
    .join-industry-content {
      width: 1200px;
      margin: 0 auto;
      padding: 40px 0 100px 0;
      justify-content: space-around;
      .industry-content-item {
        .icon {
          font-size: 120px;
        }
        p {
          font-size: 23px;
        }
      }
    }
  }
  /* 劳务邦是个好平台 */
  .join-terrace {
    height: 1147px;
    .join-terrace-title {
      .commm-title-label {
        width: 110px;
        background: #fff;
        &:first-child {
          margin-right: -5px;
        }
        &:last-child {
          margin-left: -5px;
        }
      }
    }
    .join-terrace-content {
      .terrace-content-title {
        font-size: 36px;
        margin-top: 15px;
      }
      .terrace-content {
        width: 1200px;
        margin: 100px auto 0;
        .terrace-content-left,
        .terrace-content-right {
          .content-left-item,
          .content-right-item {
            .terrace-content-item {
              margin-top: -50px;
              p {
                font-size: 32px;
                margin-bottom: 10px;
              }
              span {
                font-size: 19px;
                letter-spacing: 1px;
                margin-bottom: 5px;
              }
            }
            .icon {
              font-size: 250px;
            }
          }
          .content-left-item {
            margin-right: -12px;
            justify-content: flex-end;
            .terrace-content-item {
              margin-right: 20px;
            }
          }
          .content-right-item {
            margin-left: -8px;
            .terrace-content-item {
              margin-left: 20px;
            }
          }
          .yingzi {
            justify-content: center;
            margin-right: 30px;
          }
          .banzi {
            justify-content: center;
            margin-left: 30px;
          }
          .paizi,
          .naozi {
            margin-bottom: -50px;
          }
          .luzi,
          .fuzi {
            margin-top: -50px;
          }
        }
        /* 盘子 */
        .seven {
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 34px;
        }
      }
    }
  }
  /* 你是谁 */
  .join-user {
    top: -60px;
    padding-top: 50px;
    padding-bottom: 50px;
    .join-user-title {
      font-size: 40px;
      margin-bottom: 70px;
    }
    .join-user-content {
      width: 1200px;
      margin: 0 auto;
      .user-content-item {
        margin: 10px auto 40px;
        .icon {
          font-size: 140px;
        }
        p {
          margin-top: 20px;
          span {
            margin-bottom: 6px;
            font-size: 17px;
          }
        }
      }
    }
  }
  /* 加盟流程 */
  .join-step {
    padding-bottom: 140px;
    .join-step-title {
      .commm-title-label {
        width: 100px;
        &:first-child {
          margin-right: 25px;
        }
        &:last-child {
          margin-left: 25px;
        }
      }
    }
    .join-step-detail {
      padding: 50px 0 30px 0;
      .icon-step {
        font-size: 120px;
      }
      .icon-jiantou {
        font-size: 65px;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
  /* 加盟热线 */
  .join-tool-tel {
    margin-top: 50px;
    .btns {
      width: 180px;
      height: 60px;
      line-height: 60px;
      font-size: 23px;
      margin-right: 15px;
    }
    .telephone {
      height: 60px;
      line-height: 60px;
      margin-left: 15px;
      padding: 0 10px;
      font-size: 28px;
      span {
        font-size: 30px;
      }
    }
  }
}
/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  /* 广告区 */
  .join-swiper {
    p {
      font-size: 28px;
      letter-spacing: 3px;
      margin-top: -10px;
      margin-bottom: 15px;
    }
    span {
      font-size: 17px;
      line-height: 35px;
      letter-spacing: 1px;
    }
  }
  /* 劳务邦是个好平台 */
  .join-industry {
    .join-industry-title {
      .commm-title-label {
        width: 100px;
        &:first-child {
          margin-right: -10px;
        }
        &:last-child {
          margin-left: -10px;
        }
      }
    }
    .join-industry-content {
      width: 100%;
      padding: 40px 15px 100px 15px;
      justify-content: space-around;
      .industry-content-item {
        .icon {
          font-size: 100px;
        }
        p {
          font-size: 21px;
        }
      }
    }
  }
  /* 劳务邦是个好平台 */
  .join-terrace {
    height: 1147px;
    .join-terrace-title {
      .commm-title-label {
        width: 100px;
        background: #fff;
        &:first-child {
          margin-right: -10px;
        }
        &:last-child {
          margin-left: -10px;
        }
      }
    }
    .join-terrace-content {
      .terrace-content-title {
        font-size: 32px;
        margin-top: 5px;
      }
      .terrace-content {
        width: 100%;
        margin: 50px auto 0;
        padding: 0 15px;
        box-sizing: border-box;
        .terrace-content-left,
        .terrace-content-right {
          .content-left-item,
          .content-right-item {
            .terrace-content-item {
              margin-top: -40px;
              p {
                font-size: 25px;
                margin-bottom: 10px;
              }
              span {
                font-size: 16px;
                letter-spacing: 1px;
                margin-bottom: 5px;
              }
            }
            .icon {
              font-size: 180px;
            }
          }
          .yingzi {
            justify-content: center;
            margin-right: 30px;
          }
          .banzi {
            justify-content: center;
            margin-left: 30px;
          }
          .paizi,
          .naozi {
            margin-bottom: -50px;
          }
          .luzi,
          .fuzi {
            margin-top: -50px;
          }
        }
        .content-left-item {
          margin-right: -12px;
          justify-content: flex-end;
          .terrace-content-item {
            // margin-right: 20px;
          }
        }
        .content-right-item {
          margin-left: -8px;
          .terrace-content-item {
            margin-left: 20px;
          }
        }
        /* 盘子 */
        .seven {
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-size: 30px;
        }
      }
    }
  }
  /* 你是谁 */
  .join-user {
    top: -60px;
    padding-top: 50px;
    padding-bottom: 50px;
    .join-user-title {
      font-size: 40px;
      margin-bottom: 70px;
    }
    .join-user-content {
      width: 100%;
      .user-content-item {
        margin: 10px auto 40px;
        .icon {
          font-size: 140px;
        }
        p {
          margin-top: 20px;
          span {
            margin-bottom: 6px;
            font-size: 17px;
          }
        }
      }
    }
  }
  /* 加盟流程 */
  .join-step {
    padding-bottom: 140px;
    .join-step-title {
      .commm-title-label {
        width: 100px;
        &:first-child {
          margin-right: 25px;
        }
        &:last-child {
          margin-left: 25px;
        }
      }
    }
    .join-step-detail {
      padding: 50px 0 30px 0;
      .icon-step {
        font-size: 120px;
      }
      .icon-jiantou {
        font-size: 65px;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
  }
  /* 加盟热线 */
  .join-tool-tel {
    margin-top: 50px;
    .btns {
      width: 180px;
      height: 60px;
      line-height: 60px;
      font-size: 23px;
      margin-right: 15px;
    }
    .telephone {
      height: 60px;
      line-height: 60px;
      margin-left: 15px;
      padding: 0 10px;
      font-size: 28px;
      span {
        font-size: 30px;
      }
    }
  }
}
/* -------------适应移动端---------------- */
@media screen and (min-width: 460px) and (max-width: 700px) {
  /* 广告区 */
  .join-swiper {
    p {
      font-size: 20px;
      letter-spacing: 1px;
      margin-top: -10px;
      margin-bottom: 5px;
      box-sizing: border-box;
    }
    span {
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 1px;
    }
  }
  /* 劳务邦是个好平台 */
  .join-industry {
    .join-industry-title {
      .commm-title-label {
        width: 80px;
        &:first-child {
          margin-right: -20px;
        }
        &:last-child {
          margin-left: -20px;
        }
      }
    }
    .join-industry-content {
      width: 100%;
      padding: 20px 10px 50px 10px;
      justify-content: space-around;
      flex-wrap: wrap;
      .industry-content-item {
        width: 50%;
        .icon {
          font-size: 65px;
        }
        p {
          font-size: 17px;
          margin-top: 7px;
        }
      }
    }
  }
  /* 劳务邦是个好平台 */
  .join-terrace {
    height: 580px;
    .join-terrace-title {
      .commm-title-label {
        width: 80px;
        background: #fff;
        &:first-child {
          margin-right: -20px;
        }
        &:last-child {
          margin-left: -20px;
        }
      }
    }
    .join-terrace-content {
      .terrace-content-title {
        font-size: 28px;
        margin-top: 5px;
      }
      .terrace-content {
        width: 100%;
        margin: 40px auto 0;
        .terrace-content-left,
        .terrace-content-right {
          .content-left-item,
          .content-right-item {
            .terrace-content-item {
              margin-top: -20px;
              p {
                font-size: 20px;
                margin-bottom: 5px;
              }
              span {
                font-size: 16px;
                letter-spacing: 1px;
                margin-bottom: 5px;
                text-align: left;
              }
            }
            .icon {
              display: none;
            }
          }
        }
        .content-left-item {
          justify-content: flex-start;
          padding-left: 30px;
          box-sizing: border-box;
        }
        .content-right-item {
          padding-left: 20px;
          box-sizing: border-box;
        }
        /* 盘子 */
        .seven {
          color: #fff;
          font-size: 20px;
          position: absolute;
          bottom: -56px;
          left: 24px;
        }
      }
    }
  }
  /* 你是谁 */
  .join-user {
    top: -60px;
    padding-top: 20px;
    padding-bottom: 40px;
    .join-user-title {
      font-size: 22px;
      margin-bottom: 30px;
    }
    .join-user-content {
      width: 100%;
      .user-content-item {
        margin: 10px auto 10px;
        .icon {
          font-size: 75px;
        }
        p {
          margin-top: 10px;
          span {
            margin-bottom: 4px;
            font-size: 14px;
          }
        }
      }
    }
  }
  /* 加盟流程 */
  .join-step {
    padding-bottom: 70px;
    margin-top: -50px;
    .join-step-title {
      .commm-title-label {
        width: 70px;
        &:first-child {
          margin-right: 15px;
        }
        &:last-child {
          margin-left: 15px;
        }
      }
    }
    .join-step-detail {
      padding: 0 0 10px 0;
      .icon-step {
        font-size: 70px;
      }
      .icon-jiantou {
        font-size: 60px;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
  /* 加盟热线 */
  .join-tool-tel {
    margin-top: 30px;
    .btns {
      width: 120px;
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      margin-right: 15px;
    }
    .telephone {
      height: 50px;
      line-height: 50px;
      margin-left: 15px;
      padding: 0 10px;
      font-size: 22px;
      span {
        font-size: 25px;
      }
    }
  }
}
/* -------------适应移动端(二)---------------- */
@media screen and (max-width: 460px) {
  /* 广告区 */
  .join-swiper {
    padding: 0 5px;
    box-sizing: border-box;
    p {
      font-size: 16px;
      letter-spacing: 1px;
      margin-top: -3px;
      margin-bottom: 0;
    }
    span {
      font-size: 14px;
      // line-height: 20px;
      letter-spacing: 1px;
      // text-align: left;
    }
  }
  /* 劳务邦是个好平台 */
  .join-industry {
    .join-industry-title {
      .commm-title-label {
        width: 60px;
        &:first-child {
          margin-right: -22px;
        }
        &:last-child {
          margin-left: -22px;
        }
      }
    }
    .join-industry-content {
      width: 100%;
      padding: 20px 10px 50px 10px;
      justify-content: space-around;
      flex-wrap: wrap;
      .industry-content-item {
        width: 50%;
        .icon {
          font-size: 65px;
        }
        p {
          font-size: 17px;
          margin-top: 7px;
        }
      }
    }
  }
  /* 劳务邦是个好平台 */
  .join-terrace {
    height: 580px;
    .join-terrace-title {
      .commm-title-label {
        width: 60px;
        background: #fff;
        &:first-child {
          margin-right: -22px;
        }
        &:last-child {
          margin-left: -22px;
        }
      }
    }
    .join-terrace-content {
      .terrace-content-title {
        font-size: 22px;
        margin-top: 8px;
      }
      .terrace-content {
        width: 100%;
        margin: 30px auto 0;
        .terrace-content-left,
        .terrace-content-right {
          .content-left-item,
          .content-right-item {
            .terrace-content-item {
              margin-top: -10px;
              p {
                font-size: 20px;
                margin-bottom: 5px;
              }
              span {
                font-size: 16px;
                letter-spacing: 1px;
                text-align: left;
              }
            }
            .icon {
              display: none;
              font-size: 100px;
            }
          }
          .content-left-item {
            justify-content: flex-start;
            padding-left: 20px;
            box-sizing: border-box;
          }
          .content-right-item {
            padding-left: 10px;
            box-sizing: border-box;
          }
        }
        .seven {
          font-size: 20px;
          color: #fff;
          position: absolute;
          bottom: -56px;
          left: 22px;
        }
      }
    }
  }
  /* 你是谁 */
  .join-user {
    top: -60px;
    padding-top: 20px;
    padding-bottom: 40px;
    .join-user-title {
      font-size: 22px;
      margin-bottom: 20px;
    }
    .join-user-content {
      width: 100%;
      .user-content-item {
        margin: 10px auto 10px;
        .icon {
          font-size: 60px;
        }
        p {
          margin-top: 10px;
          span {
            margin-bottom: 3px;
            font-size: 12px;
          }
        }
      }
    }
  }
  /* 加盟流程 */
  .join-step {
    padding-bottom: 50px;
    margin-top: -50px;
    .join-step-title {
      .commm-title-label {
        width: 65px;
        &:first-child {
          margin-right: 12px;
        }
        &:last-child {
          margin-left: 12px;
        }
      }
    }
    .join-step-detail {
      padding: 0 0 10px 0;
      .icon-step {
        font-size: 70px;
      }
      .icon-jiantou {
        font-size: 60px;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
  /* 加盟热线 */
  .join-tool-tel {
    margin-top: 10px;
    .btns {
      width: 100px;
      height: 40px;
      line-height: 40px;
      font-size: 19px;
      margin-right: 7px;
    }
    .telephone {
      height: 40px;
      line-height: 40px;
      margin-left: 7px;
      padding: 0 10px;
      font-size: 21px;
      span {
        font-size: 24px;
      }
    }
  }
}
</style>
